import React from 'react'
import { PageProps } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const NotFound = ({ data }: PageProps<any>) => (
    <Layout>
        <SEO title='404: Not found' />
        <div
            style={{
                textAlign: 'center',
                maxWidth: 960,
                margin: 'auto',
                padding: '48px',
            }}
        >
            <h2>Whoa there! There's no page here!</h2>
        </div>
    </Layout>
)

export default NotFound
